import { storeConstants } from '../constants/index';

const initialState = {
  
};

const FilterReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default FilterReducer;
