export const portugalLabels = {
  deleteOrderMessage: "Do you want to delete this order?",
  deleteSuccessButton: "Ok",
  order_details: "Detalhes da encomenda",
  period_orders: "PERIOD ORDERS",
  editing_order: "EDITING ORDER",
  noOfPhar: "Número de Farmácias:",
  wholesaler_placeholder: "Selecione seu ARMAZENISTA",
  wholesaler_error: "Por favor selecione seu ARMAZENISTA",
  date_error: "Please select date",
  filter_placeholder: "Digite aqui para filtrar produtos…",
  date_placeholder: "Select Date",
  cleara_button: "LIMPAR",
  no_date_available: "No more date available please edit existing order!",
  products_th: "Produtos",
  units_th: "Unidades",
  discount: "Descontos",
  total_value: "Valor",
  total_discount: "Desconto em fatura",
  final_value: "Valor final da fatura",
  confirm_button: "CONFIRMAR",
  cancel_button: "CANCELAR",
  unit_select_error: "Por favor adicione algumas unidades!",
  order_confirm_message:
    "Por favor selecione CONFIRMAR ENCOMENDAS para que sejam enviadas",
  cancel_message: "Tem certeza de que deseja CANCELAR esta encomenda?",
  order_success_message:
    "As encomendas serão enviadas para os armazenistas selecionados. A faturação e os prazos de entrega estão sujeitos às politicas de cada armazenista. A relação contratual é com o armazenista selecionado para cada encomenda. Não são emitidos créditos de devoluções de produtos vendidos em campanha.",
  api_error1: "Sinto muito, algo deu errado...",
  api_error2: "Por favor volte mais tarde",
  order_no_history: "Order No",
  date_history: "Date",
  status_history: "Status",
  order_details_history: "Order Details",
  sku_th_history: "SKU",
  units_th_history: "Units",
  on_invoice_discount_history: "On Invoice Discount",
  filter_error: "Produto não disponível",
  placing_new_order: "PLACING A NEW ORDER",
  new_order_button: "NEW ORDER",
  no_of_phar_error: "Please enter number of pharmacies!",
  cancel_order: 'SIM / APAGAR ENCOMENDA',
  keep_order: 'NÃO / MANTER ENCOMENDAS',
  confirm_pop_up_button: 'CONFIRMAR',
  go_back: 'VOLTAR',
  sku_th: 'CN',
  total_units: "Unidades Totales",
};