export const iercmLink = [
  {
    skuIdentifier: "12742",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvOGQwMDM5YWQtNDNlNi00YjhlLThkNWItNmQ3MmJmMjY3NzMwLnBkZg==",
  },
  {
    skuIdentifier: "60000000137592",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvNDBlMTU5OTQtNTg5YS00Y2EzLTk1N2MtOTI3ZDNkNWNjNjU2LnBkZg==",
  },
  {
    skuIdentifier: "5168640351",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvNDBlMTU5OTQtNTg5YS00Y2EzLTk1N2MtOTI3ZDNkNWNjNjU2LnBkZg==",
  },
  {
    skuIdentifier: "5168640341",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvNDBlMTU5OTQtNTg5YS00Y2EzLTk1N2MtOTI3ZDNkNWNjNjU2LnBkZg==",
  },
  {
    skuIdentifier: "5168650521",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvMDNkOGZjM2MtZDYxYy00ZGI5LThmYjUtZDg2ZTZjNzgxNDU5LmRvY3g=",
  },
  {
    skuIdentifier: "60000000003661",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvMDNkOGZjM2MtZDYxYy00ZGI5LThmYjUtZDg2ZTZjNzgxNDU5LmRvY3g=",
  },
  {
    skuIdentifier: "5168650541",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvMDNkOGZjM2MtZDYxYy00ZGI5LThmYjUtZDg2ZTZjNzgxNDU5LmRvY3g=",
  },
  {
    skuIdentifier: "5168650551",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvMDNkOGZjM2MtZDYxYy00ZGI5LThmYjUtZDg2ZTZjNzgxNDU5LmRvY3g=",
  },
  {
    skuIdentifier: "60000000115906",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvNmNhOGUxYTYtYzNmYS00YTA3LTlmNjQtOGJkYWQ5ZDk4Y2YzLnBkZg==",
  },
  {
    skuIdentifier: "10000000075198",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTI3NTIvN2ZjYWI5NzktZTMxMS00ZDkxLThlYmMtYWEyODNiMWUwMTZmLnBkZg==",
  },
  {
    skuIdentifier: "10000000081160",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTI3NTIvN2ZjYWI5NzktZTMxMS00ZDkxLThlYmMtYWEyODNiMWUwMTZmLnBkZg==",
  },
  {
    skuIdentifier: "AR6288",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvODNmNjAyNjQtNTQxMi00YTcyLWE3YTktNjg0NDJmNzZlODc3LnBkZg==",
  },
  {
    skuIdentifier: "60000000108672",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvODNmNjAyNjQtNTQxMi00YTcyLWE3YTktNjg0NDJmNzZlODc3LnBkZg==",
  },
  {
    skuIdentifier: "AR6206",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvZjUxY2I2NzktYTg5Yy00ZDViLTkxYTEtYjliMjkzZGQ2N2I3LnBkZg==",
  },
  {
    skuIdentifier: "AR8604",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvZjUxY2I2NzktYTg5Yy00ZDViLTkxYTEtYjliMjkzZGQ2N2I3LnBkZg==",
  },
  {
    skuIdentifier: "60000000005399",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvNDMzMjVmN2EtMDlhMy00ZTE3LTg3M2EtOTA1Zjg4MjgwOWY0LnBkZg==",
  },
  {
    skuIdentifier: "60000000005400",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvZmEyNjlmNGEtNDkwZS00Nzk0LWE3MjktODA1MDg1ZTJjMjcyLnBkZg==",
  },
  {
    skuIdentifier: "60000000119320",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvZmEyNjlmNGEtNDkwZS00Nzk0LWE3MjktODA1MDg1ZTJjMjcyLnBkZg==",
  },
  {
    skuIdentifier: "60000000119319",
    link: "https://r5.app.yellow.ai/api/blob-proxy/render/r5-ym-uploads/eDE2NzM0MjM1NTMxODAvZmEyNjlmNGEtNDkwZS00Nzk0LWE3MjktODA1MDg1ZTJjMjcyLnBkZg==",
  },
];
