import React from "react";
import OrderCard from "./OrderCard";

const OrderHistory = ({
  openOrders,
  setDeleteOrderId,
  closeOrders,
  setActiveTab,
  createNewOrder,
  handleEditIcon,
  setMessageType,
  setShowPopUp,
  setPopUpMessage,
  labels,
}) => {
  return (
    <div style={{ paddingBottom: "25px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "7px",
        }}
      >
        <div
          onClick={() => {
            setActiveTab(1);
            createNewOrder();
          }}
          className="new-order-button"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "20px",
                width: "20px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                background: "#f26633",
                color: "white",
              }}
            >
              +
            </div>
            <span style={{ fontWeight: "bold", fontSize: "14.5px" }}>
              {labels.new_order_button}
            </span>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        {openOrders.map((order, index) => {
          return (
            <OrderCard
              labels={labels}
              key={index}
              setDeleteOrderId={setDeleteOrderId}
              type={"open"}
              setPopUpMessage={setPopUpMessage}
              setShowPopUp={setShowPopUp}
              orderData={order}
              handleEditIcon={handleEditIcon}
              setMessageType={setMessageType}
            />
          );
        })}

        {closeOrders.map((order, index) => {
          return (
            <OrderCard
              labels={labels}
              key={index}
              type={"close"}
              orderData={order}
            />
          );
        })}
      </div>
      {openOrders?.length === 0 && closeOrders?.length === 0 && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          No order available
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
