import React, { useState } from "react";
import Edit from "./SVGIcon/edit.svg";
import DeleteIcon from "./SVGIcon/deleteIcon.svg";
import DownArrow from "./SVGIcon/downArrow.svg";
import WhiteDownArrow from "./SVGIcon/whiteDownArrow.svg";

const OrderCard = ({ type, orderData,labels, setDeleteOrderId, handleEditIcon, setMessageType, setShowPopUp, setPopUpMessage }) => {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="open-order-card"
      style={{
        background: type === "open" ? "white" : "#3f3f3f",
        color: type === "open" ? "black" : "#959595",
        border: type === "open" ? "1px solid grey" : "",
      }}
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div style={{ display: "flex", fontSize: 13.5, alignItems: "center" }}>
        <div className="flex1">
          <div style={{ display: "flex" }}>
            <span style={{ flex: 1.1 }}>
              {orderData?.wholesalerName} - {orderData?.accountCode}
            </span>
            <span className="flex1">
              <span className="orange-bold" style={{fontStyle:'italic'}}>{labels?.date_history}:</span>
              <span style={{ marginLeft: "5px" }}>
                {orderData?.orderedDateString}
              </span>
            </span>
          </div>
          <div style={{ display: "flex" }}>
            <span style={{ flex: 1.1 }}>
              <span style={{ color: "grey", fontWeight: "bold", fontStyle:'italic' }}>
                {labels?.order_no_history}
              </span>
              <span style={{ marginLeft: "5px" }}>{orderData?.orderId}</span>
            </span>

            <span className="flex1">
              <span className="orange-bold" style={{fontStyle:'italic'}}>{labels?.status_history}:</span>
              <span style={{ marginLeft: "5px" }}>{orderData?.status}</span>
            </span>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          {type === "open" && (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "24px",
                  marginLeft: "5px",
                  height: "23px",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleEditIcon(orderData);
                }}
              >
                <img src={Edit} className="icon-click-effect" alt="edit-icon" />
              </div>
              <div
                style={{
                  width: "25px",
                  marginLeft: "5px",
                  height: "23px",
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setDeleteOrderId(orderData.orderId);
                  setMessageType("delete-order");
                  setShowPopUp(true);
                  setPopUpMessage(labels?.deleteOrderMessage);
                }}
              >
                <img
                  src={DeleteIcon}
                  className="icon-click-effect"
                  alt="delete-icon"
                />
              </div>
            </div>
          )}
          <img
            src={type === "open" ? DownArrow : WhiteDownArrow}
            alt="delete-icon"
            style={{
              cursor: "pointer",
              transform: !open ? "rotate(0deg)" : "rotate(180deg)",
            }}
          />
        </div>
      </div>
      {open && (
        <div style={{ fontSize: 14 }}>
          <span style={{ fontWeight: "bold", fontStyle:'italic' }}>{labels?.order_details_history}</span>
          <div style={{ marginTop: "1px" }}>
            <div
              style={{
                display: "flex",
                borderBottom: "1px solid #e6e6e6",
                paddingBottom: "3px",
                fontSize:'13px'
              }}
            >
              <span className="orange-bold" style={{flex:1.6, fontStyle:'italic'}}>{labels?.sku_th_history}</span>
              <span className="orange-bold" style={{flex:0.4, fontStyle:'italic'}}>{labels?.units_th_history}</span>
              <span className="orange-bold flex1" style={{fontStyle:'italic'}}>{labels?.on_invoice_discount_history}</span>
            </div>
            {orderData?.orderLineItems &&
              orderData?.orderLineItems?.map((orderItem, index) => {
                return (
                  <div style={{ marginTop: "3px" }} key={index}>
                    <div style={{ display: "flex" }}>
                    <span style={{ fontWeight: "bold", flex:1.6 }}>{orderItem.sku}</span>
                      <span style={{flex:0.4}}>{orderItem?.units}</span>
                      <span className="flex1">
                        {orderItem?.onInvoiceDiscount}%
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderCard;
